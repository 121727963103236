import React from 'react';
import {Link} from 'gatsby'

const createPaginationNav = (slug, pageContext) => {
    if(pageContext.totalPages > 1){
      const totalPages = Array.from(Array(pageContext.totalPages));
      return totalPages.map((page, index) => {    
        return(
          <li key={'link_'+index}>
            <Link to={index === 0 ? slug : `${slug}page/${index+1}`}>{index+1}</Link>
          </li>
        )
      })
    }
}

export default createPaginationNav

