import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`

   .cards-wrapper{
    width: 100%;
    display: grid;
    grid-gap: 4rem 2rem;
    position: relative;
    z-index: 100;
    padding: 0 2rem ;
    grid-template-columns: repeat(1, 1fr);

        @media only screen and (min-width: 450px) {
            padding:0 4rem ;
        }
        @media only screen and (min-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media only screen and (min-width: 1024px) {
            grid-template-columns: repeat(3, 1fr);
            padding:0rem 10rem 0rem 10rem;
        }
    }

`

export { Styling }