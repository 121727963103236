import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`
/* background:var(--blue_light); */
width: 100%;
padding:0 4rem 4rem 4rem;
position: relative;
display: flex;
z-index: 10;


    
    @media only screen and (min-width: 1024px) {
        padding:0 10rem 4rem 10rem;
    }

    .pagination{
    width:100%;
    display: flex;
    justify-content:space-between;
    }
    
    .prev,
    .next{

    }

    .prev{
    justify-self: flex-start;
    }


    .next{
        justify-self: flex-end;
    }

    
    a{
    text-decoration:none;
    color:var(--blue_dark);
    ${({theme}) => theme.fontSize('16')}  
    
        @media only screen and (min-width: 1024px) {
            ${({theme}) => theme.fontSize('25')}  
            // padding:0 4rem 4rem 4rem;
        }
        
        &[aria-current="page"]{
            color:var(--white);
        }

    }

    ul{
    position:absolute;
    left:50%;
    transform:translateX(-50%);
    display: inline-flex;

        li{
        margin-right:2rem;
        }

    }

`

export { Styling }