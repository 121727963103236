import React from 'react'
import Hero from '../components/global/Hero'
import IntroSectionReuseable from '../components/reuseable/IntroSectionReuseable';
import Card from '../components/reuseable/Card'
import { graphql } from 'gatsby'
import { Styling } from '../styles/caseStudyArchive/styled.js'
import Pagination from '../components/reuseable/Pagination';
import CTASection from '../components/global/CTASection';
import Texture from '../components/animation/Texture'
import { Helmet } from 'react-helmet';

export default function CaseStudy({data, pageContext}) {

  const heroImage = pageContext.page.customHeroImage.heroImage
  const title = pageContext.page.title
  const caseStudies = data.caseStudies.nodes
console.log(data)
    return (
        <Styling>
          <Helmet>
            <title>{data.wpPage.seo.title}</title>
            <meta name="description" content={data.wpPage.seo.metaDesc} />
            <meta name="og:description" content={data.wpPage.seo.metaDesc} />
            <meta property="og:title" content={data.wpPage.seo.title} />          
            <meta property="og:image" content={'https://morlandbathrooms.com'+data.wpPage.customHeroImage.heroImage.localFile.publicURL} />
          </Helmet>
          <div className="offpage-translate offpage-translate-bg"></div>
            <div className="offpage-translate">
              <Hero image={heroImage} title={title}/>
              <IntroSectionReuseable defaultPageFields={pageContext.page.defaultPageFields} />
              <ul className="cards-wrapper">
                {caseStudies.map(caseStudy => {
                  return(
                    <li className="card">
                      <Card 
                        button={true} 
                        link={`/case-studies/${caseStudy.slug}`} 
                        title={caseStudy.title} 
                        image={caseStudy.customHeroImage.heroImage}
                      />
                    </li>
                  )
                })
              }
              </ul>
              <Pagination slug={'/case-studies/'} pageContext={pageContext} />
              <CTASection ctaType={'chat'} />
              <Texture 
              className='texture full' 
              opacity={0.05}  
              position='absolute'
              bottom="0"
              height={''}
              top="0"
              watchScroll={false}
              />
          </div>
        </Styling>
    )
}

export const query = graphql`
  query($offset: Int!, $postsPerPage:Int! ) {
    wpPage(slug: {eq: "case-studies"}) {
      seo {
        title
        metaDesc
      }
      customHeroImage {
        heroImage {
          localFile{
            publicURL
          }
        }
      }
    }
    caseStudies: allWpCaseStudy(sort: {fields: date, order: DESC}, skip:$offset, limit: $postsPerPage)  {
      nodes {
        slug
        title
        customHeroImage {
          heroImage {
            localFile{
              childImageSharp {
                gatsbyImageData(
                  width: 1500
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`