import React from 'react'
import { Styling } from './styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby'

export default function Card({image, title, button, link}) {
    const theImage = getImage(image.localFile);
    return (
        <Styling>
            <article>
                <Link to={link}>
                    <GatsbyImage image={theImage} className="image" alt={'test'} />
                    <h2>{title}</h2>
                    {button === true ? <div className="button">Read more</div> : "" }
                </Link>
            </article>
        </Styling>
    )
}
