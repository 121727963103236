import React from 'react'
import { Styling } from './styled.js'
import createNext from './createNext'
import createPrev from './createPrev'
import createPaginationNav from './createPaginationNav'

export default function Pagination({slug, pageContext}) {
    return (
        <Styling>
            <div className="pagination">
              {
                createPrev(slug, pageContext)
              }

              <ul>{createPaginationNav(slug, pageContext)}</ul>
             
              {
                createNext(slug, pageContext)
              }
            </div>
        </Styling>
    )
}
