import React from 'react';
import {Link} from 'gatsby'

const createPrev = (slug, pageContext) => {
    return(
      pageContext.current > 2  ? 
        <Link className="prev" to={`${slug}page/`+(pageContext.current-1)}>Prev</Link> 
      : pageContext.current == 2  ? 
        <Link className="prev" to={slug}>Prev</Link> 
      : <span></span>
    )
}

export default createPrev