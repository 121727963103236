import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`
    .image{
    margin-bottom: 2rem;
    background:var(--white);
    overflow: hidden;
    //border:1rem solid var(--white);
    background:var(--white);
    height: 0;
    position: relative;
    padding-bottom: 56%;
      

        img{
        ${({theme}) => theme.easing('0.5')}  
        }

    }

    a{
    color:var(--blue_dark);
    text-decoration:none;
    ${({theme}) => theme.fontSize('20')}  
    }

    h2{
    margin-left:1rem;
    }

    &:hover{
        .image{
            img{
            transform:scale(1.2)
            }
        }
    }

    .button{
    ${({theme}) => theme.button('--blue_dark')}  
    background:var(--blue_dark);
    color:var(--white);
    width: auto;
    position: relative;
    display: inline-flex;
    margin:2rem 0 0 1rem;

        &:hover{
        background:var(--white);
        color:var(--blue_dark);
        }

    }

`

export { Styling }