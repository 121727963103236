import React from 'react';
import {Link} from 'gatsby'

const createNext = (slug, pageContext) => {
    if(pageContext.current >= 1 && pageContext.totalPages !== pageContext.current){
        return(
            <Link className="next" to={`${slug}page/`+(pageContext.current+1)}>Next</Link> 
        )
    }
}


export default createNext